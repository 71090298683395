import { FormControl } from "@material-ui/core/";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import UserService from "../../services/UserService";
import { useStyles } from "../App";
import { useView } from "../../context/ViewContext";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Alert from "@material-ui/lab/Alert";

export default function CreateUser() {
  const firstName = useRef("");
  const lastName = useRef("");
  const username = useRef("");
  const email = useRef("");
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState("");
  const currentView = useView();
  const [error, setError] = useState(false);

  useEffect(() => {
    currentView.setCurrentView("Create New User");
  }, []);

  const handleChange = (event) => {
    setCompany(event.target.value);
  };

  const handleSubmit = () => {
    if (
      !firstName.current.value ||
      !lastName.current.value ||
      !username.current.value ||
      !email.current.value ||
      !company.id
    ) {
      setError(true);
      return;
    }

    let data = {
      firstName: firstName.current.value,
      lastName: lastName.current.value,
      username: username.current.value,
      email: email.current.value,
      company: company.id,
    };
    UserService.createNewUser(data).then((response) => {
      firstName.current.value = "";
      lastName.current.value = "";
      username.current.value = "";
      email.current.value = "";
      setCompany("");
      setError(false);
    });
  };

  const getCompanies = () => {
    UserService.getCompanies().then((response) => {
      setCompanies(response.data);
    });
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const classes = useStyles();

  return (
    <>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Card>
            <CardContent>
              <h1>Create New User</h1>
            </CardContent>
          </Card>
          {error ? (
            <div style={{ paddingTop: 10 }}>
              <Alert variant="outlined" severity="error">
                Please do not leave any options blank!
              </Alert>
            </div>
          ) : null}
          <div style={{ paddingTop: 10 }}>
            <Card>
              <CardContent>
                <h3>First Name</h3>
                <TextField
                  id="firstName"
                  inputRef={firstName}
                  label="First Name"
                />
              </CardContent>
            </Card>
          </div>

          <div style={{ paddingTop: 10 }}>
            <Card>
              <CardContent>
                <h3>Last Name</h3>
                <TextField
                  id="lastName"
                  inputRef={lastName}
                  label="Last Name"
                />
              </CardContent>
            </Card>
          </div>

          <div style={{ paddingTop: 10 }}>
            <Card>
              <CardContent>
                <h3>Username</h3>
                <TextField id="username" inputRef={username} label="Username" />
              </CardContent>
            </Card>
          </div>
          <div style={{ paddingTop: 10 }}>
            <Card>
              <CardContent>
                <h3>Email</h3>
                <TextField id="email" inputRef={email} label="Email" />
              </CardContent>
            </Card>
          </div>
          <div style={{ paddingTop: 10 }}>
            <Card>
              <CardContent>
                <h3>Company</h3>
                <Select
                  id="companySelect"
                  value={company}
                  onChange={handleChange}
                  style={{ minWidth: 120, padding: 8 }}
                >
                  {" "}
                  {companies.map((comp) => (
                    <MenuItem value={comp}>{comp.name}</MenuItem>
                  ))}
                </Select>
              </CardContent>
            </Card>
          </div>
          <div style={{ paddingTop: 10 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </div>
        </Container>
      </main>
    </>
  );
}
