import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormGroup,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useStyles } from "./App";
import { useAuth } from "../context/AuthContext";
import { setCurrentUser } from "../context/AuthContext";
import UserService from "../services/UserService";
import Swal from "sweetalert2";
import { useView } from "../context/ViewContext";

export default function EmailPasswordChange() {
  const passwordRef = useRef();
  const [token, setToken] = useState(window.location.pathname.split("/"));
  const [redirect, setRedirect] = useState(false)
  const confirmPasswordRef = useRef();
  const [error, setError] = useState("");
  const currentView = useView();
  //const [loading, setLoading] = useState(false);
  const history = useHistory();

  const classes = useStyles();

  useEffect(() => {
    currentView.setCurrentView("Email Password Reset");
  }, []);

  const saveInfo = () => {
    if (passwordRef.current.value != confirmPasswordRef.current.value) {
      setError("Passwords are not the same");
    } else if (passwordRef.current.value.length < 7) {
      setError("Password must be at least 8 characters long")
    } else {
      let length = token.length - 1;
      var data = {
        token: token[length],
        password: passwordRef.current.value,
      };

      UserService.emailChangePassword(data).then((response) => {
        if (response.data == "Success") {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Password has been changed",
            didClose: () => {
              GoToLogin();
            },
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "Password has not been changed",
          });
        }
      });
    }
  };

  const GoToLogin = () => {
    setRedirect(true)
  };

  return (
    <>
      {redirect ? <Redirect to="/login" /> : null }
      <main className={classes.content} style={{ padding: 10 }}>
        <div className={classes.appBarSpacer} />
        <Card>
          <CardContent>
            <h2 className="text-center mb-4">Change Password</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <FormControl>
              <FormGroup id="password">
                <TextField
                  id="password"
                  label="New Password"
                  inputRef={passwordRef}
                  required
                />
              </FormGroup>
              <FormGroup id="confirmPassword">
                <TextField
                  id="confirmPassword"
                  label="Confirm Password"
                  inputRef={confirmPasswordRef}
                  required
                />
              </FormGroup>
              <div style={{ paddingTop: 10 }}></div>
              <Button
                onClick={() => saveInfo()}
                variant="contained"
                color="primary"
                className="w-100"
                type="submit"
              >
                Save
              </Button>
            </FormControl>
          </CardContent>
        </Card>
      </main>
    </>
  );
}
