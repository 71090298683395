import Link2 from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TaskOrderService from "../services/TaskOrderService";
import Title from "./Title";


const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

export default function ActiveTaskOrderView() {
  const classes = useStyles();

  const [taskOrderCount, setTaskOrderCount] = useState();

  const retrieveTaskOrderCount = () => {
    TaskOrderService.getActiveTaskOrders().then((response) => {
      setTaskOrderCount(response.data.length);
    });
  };

  useEffect(() => {
    retrieveTaskOrderCount();
  }, []);

  return (
    <>
      <Title>Active Task Orders</Title>
      <Typography component="p" variant="h4">
        {taskOrderCount}
      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
        {new Date().toLocaleDateString()}
      </Typography>
      <div>
        <Link to="/taskOrders/" style={{ textDecoration: "none" }}>
          <Link2 color="primary">View Task Orders</Link2>
        </Link>
      </div>
    </>
  );
}
