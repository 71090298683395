import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import React, { useEffect, useState, useRef } from "react";
import { useStyles } from "../App";
import SubTaskOrderService from "../../services/SubTaskOrderService";
import { Link } from "react-router-dom";
import TaskOrderService from "../../services/TaskOrderService";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useView } from "../../context/ViewContext";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import EditIcon from '@material-ui/icons/Edit';
import TaskOrder from "../TaskOrder";

export default function AdminTaskOrderDetail(props) {
  const [subTasksOrders, setSubTaskOrders] = useState();
  const [active, setActive] = useState(props.location.state.active);
  const [edit, setEdit] = useState(false)
  const description = useRef();
  const [users, setUsers] = useState();
  const classes = useStyles();
  const [editHeader, setEditHeader] = useState(false);
  const [header, setHeader] = useState(props.location.state.number);
  const [temp, setTemp] = useState("")

  const currentView = useView();

  const retrieveSubTaskOrders = () => {
    SubTaskOrderService.getSubWithParent(props.match.params.id).then(
      (response) => {
        setSubTaskOrders(response.data);
      }
    );
  };

  

  const editTitle = () => {
    editHeader ? setEditHeader(false) : setEditHeader(true);
  };

  const save = () => {
    editHeader ? setEditHeader(false) : setEditHeader(true);
    setHeader(temp)

    // make api call to update database
   
    let data = {
      id: props.location.state.id,
      number: temp,
      active: active,
      description: description.current.value,
      pubDate: props.location.state.pubDate,
    }


    TaskOrderService.updateTitle(data).then((response) => {
    })


  }

  const handleHeaderChange = event => {
    setTemp(event.target.value);

  };

  const saveDescription = () => {
    let data = {
      id: props.location.state.id,
      active: active,
      description: description.current.value,
      pubDate: props.location.state.pubDate,
      number: props.location.state.number,
    };

    TaskOrderService.updateDescription(data).then((response) => {
    });
  };

  const saveActive = () => {
    let data = {
      id: props.location.state.id,
      active: active,
      saveDescription: props.location.state.description,
      pubDate: props.location.state.pubDate,
      number: props.location.state.number,
    };
    TaskOrderService.updateActive(data).then((response) => {
    });
  };

  const getUsersForTask = () => {
    TaskOrderService.getUsersForTask(props.location.state.id).then(
      (response) => {
        setUsers(response.data);
      }
    );
  };

  useEffect(() => {
    retrieveSubTaskOrders();
  }, []);

  const handleChange = (event) => {
    active ? setActive(false) : setActive(true);
  };

  useEffect(() => {
    currentView.setCurrentView("Admin User Detail");
  }, []);

  useEffect(() => {
    getUsersForTask();
  }, []);

  return (
    <>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Grid>
            <Card>
            <CardContent>
            {editHeader ? (
              <h1 style={{ padding: 0 }}>
                <TextField onChange={handleHeaderChange} defaultValue={header} />
              </h1>
            ) : (
              <h1 style={{ padding: 0 }}>{header}</h1>
            )}

            {editHeader ? (
              <div style={{ float: "left", display: "inline" }}>
                <Button style={{ marginBottom: "10px" }} onClick={() => editTitle()}>
                  <EditIcon style={{ marginRight: "10px" }} />
                  Edit
                </Button>
                <Button
                  style={{ marginLeft: "10px", marginBottom: "10px" }}
                  variant="contained"
                  color="primary"
                  onClick={() => save()}
                >
                  Save
                </Button>
              </div>
            ) : (
              <div style={{ float: "left", display: "inline" }}>
                <Button style={{marginBottom: "10px"}} onClick={() => editTitle()}>
                  <EditIcon style={{ marginRight: "10px" }} />
                  Edit
                </Button>
              </div>
            )}
          </CardContent>
            </Card>
            <div style={{ paddingTop: 10 }}>
              <Link
                to={{
                  pathname: `/createSubTaskOrder/`,
                  state: props.location.state.id,
                }}
                style={{ textDecoration: "none" }}
              >
                <Button variant="contained" color="primary">
                  Create Sub Task
                </Button>
              </Link>
            </div>
            <Grid
              container
              direction="row"
              spacing="2"
              style={{ paddingTop: 20 }}
            >
              <Grid item xs={4}>
                <Card style={{ height: 300 }}>
                  <CardContent>
                    <h2>Description</h2>
                  
                    <TextField
                    id="outlined-multiline-static"
                      multiline
                      style={{ width: 300 }}
                      rows={4}
                      inputRef={description}
                      placeholder={props.location.state.description}
                      defaultValue={props.location.state.description}
                      variant="outlined"
                    />
                    <div style={{ paddingTop: 20 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => saveDescription()}
                      >
                        Save
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={2}>
                <Card style={{ height: 210 }}>
                  <CardContent>
                    <h2>Active</h2>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      <Checkbox checked={active} onChange={handleChange} />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      <span style={{ paddingTop: 20 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => saveActive()}
                        >
                          Save
                        </Button>
                      </span>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card>
                  <CardContent>
                    <h2>Assigned Users</h2>
                    <List style={{ maxHeight: 200, overflow: "auto" }}>
                      {users
                        ? users.map((user) => (
                            <ListItem>
                              {user.firstName} {user.lastName}
                            </ListItem>
                          ))
                        : null}
                    </List>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card>
                  <CardContent>
                    <h2>Sub Tasks</h2>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Sub Task</TableCell>
                          <TableCell>Go to</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {subTasksOrders
                          ? subTasksOrders.map((subTask) => (
                              <TableRow key={subTask.id}>
                                <TableCell>{subTask.number}</TableCell>
                                <TableCell>
                                  <Link
                                    to={`/subTaskOrderDetail/${subTask.id}`}
                                  >
                                    <ArrowForwardIcon
                                      style={{ color: "gray" }}
                                    />
                                  </Link>
                                </TableCell>
                              </TableRow>
                            ))
                          : null}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </main>
    </>
  );
}
