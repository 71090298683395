import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TaskOrderService from "../services/TaskOrderService";
import { useStyles } from "./App";
import { useView } from "../context/ViewContext";
import { Redirect, useHistory } from "react-router-dom";

export default function TaskOrder() {
  const [activeTasks, setActiveTasks] = useState(true);
  const [taskOrders, setTaskOrders] = useState([]);
  const currentView = useView();
  const history = useHistory();
  const logout = false;

  const colors = [
    "#ffcdd2",
    "#f8bbd0",
    "#e1bee7",
    "#d1c4e9",
    "#c5cae9",
    "#bbdefb",
    "#b3e5fc",
    "#b2ebf2",
    "#c8e6c9",
    "#dcedc8",
    "#f0f4c3",
    "#fff9c4",
    "#ffecb3",
    "#ffe0b2",
    "#ffccbc",
  ];

  useEffect(() => {
    currentView.setCurrentView("Task Orders");
  }, []);

  function compare(a, b) {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  }

  const retrieveTaskOrders = () => {
    TaskOrderService.getActiveTaskOrders()
      .then((response) => {
        setTaskOrders(response.data.sort(compare));
      })
      .catch((error) => {
        //logout = true;
        history.push("/logout/")
      });
  };

  useEffect(() => {
    retrieveTaskOrders();
  }, []);

  const handleChange = (event) => {
    setActiveTasks(event.target.value);
    activeTasks
      ? TaskOrderService.getArchivedTaskOrders().then((response) => {
          setTaskOrders(response.data);
        })
      : TaskOrderService.getActiveTaskOrders().then((response) => {
          setTaskOrders(response.data);
        });
  };

  const classes = useStyles();

  return (
    <>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {logout ? <Redirect to="/logout" /> : null}
        <Container maxWidth="xl" className={classes.container}>
          <Typography
            className={classes.title}
            variant="h2"
            color="textPrimary"
          >
            {activeTasks ? (
              <b>Active Task Orders</b>
            ) : (
              <b>Archived Task Orders</b>
            )}
            <div>
              <Select
                labelId="demo-simple-select-placeholder-label-label"
                id="demo-simple-select-placeholder-label"
                value={activeTasks}
                onChange={handleChange}
                placeholder="Active"
                className={classes.selectEmpty}
              >
                <MenuItem value={true}>
                  <b>Show Active</b>
                </MenuItem>
                <MenuItem value={false}>
                  <b>Show Archived</b>
                </MenuItem>
              </Select>
            </div>
          </Typography>

          <Grid container spacing={3}>
            {taskOrders.map((task, index) => (
              <Grid item xs={3}>
                <Link
                  to={`/taskOrderDetail/${task.id}`}
                  props={task}
                  style={{ textDecoration: "none" }}
                >
                  <Card
                    style={{ backgroundColor: colors[index % colors.length] }}
                    raised={false}
                    className={classes.root}
                  >
                    <CardContent>
                      <Typography
                        className={classes.title}
                        variant="h5"
                        color="textPrimary"
                      >
                        <b>Task Order {task.number}</b>
                      </Typography>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                      >
                        {task.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
    </>
  );
}
