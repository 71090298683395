import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import BarChartIcon from "@material-ui/icons/BarChart";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ListAltIcon from "@material-ui/icons/ListAlt";
import React from "react";
import { Link } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import List from "@material-ui/core/List";

export default function SecondaryListItems(props) {
  return (
    <List>
      <ListSubheader inset>Saved reports</ListSubheader>
      <Link style={{textDecoration: "none", color: "black"}} to="/monthReport" >
      <ListItem button>
        
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Current Month" />
        
      </ListItem>
      </Link>
      <Link style={{textDecoration: "none", color: "black"}} to="/lastMonthReport" >
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Last Month" />
      </ListItem>
      </Link>
      <Link style={{textDecoration: "none", color: "black"}} to="/lastQuarter/" >
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Last Quarter" />
      </ListItem>
      </Link>
      <Link
        to={"/logout"}
        onClick={() => props.close()}
        style={{ textDecoration: "none", color: "black" }}
      >
        <ListItem
          style={
            props.open
              ? { position: "fixed", bottom: 0, width: 240, paddingBottom: 10 }
              : { position: "fixed", bottom: 0, width: 70, paddingBottom: 10 }
          }
          button
        >
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={props.open ? "Logout" : ""} />
        </ListItem>
      </Link>
    </List>
  );
}
