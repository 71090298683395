import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState, useRef } from "react";
import TaskOrderService from "../services/TaskOrderService";
import Button from "@material-ui/core/Button";
import { useStyles } from "./App";
import { subDays } from "date-fns";
import SubTaskOrderResponseService from "../services/SubTaskOrderResponseService";
import ReactTable from "./ReactTable";
import { Document, Page, pdfjs } from "react-pdf";
import PDFService from "../services/PDFService";
import DateFnsUtils from "@date-io/date-fns";
import useWindowSize from "./useWindowSize";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import { useAuth } from "../context/AuthContext";
import { Redirect, useHistory } from "react-router-dom";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useView } from "../context/ViewContext";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function MonthReport(props) {
  const currentUser = useAuth();
  const [taskOrderDetail, setTaskOrderDetail] = useState("");
  const [link, setLink] = useState();
  const [numPages, setNumPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [taskOrders, setTaskOrders] = useState([]);
  const [report, setReport] = useState();
  const currentView = useView();
  let newDate = subDays(new Date(), 0);
  const firstUpdate = useRef(true);
  const start = startOfMonth(newDate, { weekStartsOn: 1 }).getTime();
  const end = endOfMonth(newDate, { weekStartsOn: 1 }).getTime();
  const [selectedStartDate, setSelectedStartDate] = React.useState(start);
  const [selectedEndDate, setSelectedEndDate] = React.useState(end);
  const history = useHistory();
  const logout = false;

  const size = useWindowSize();

  const handleChange = (event) => {
    setTaskOrderDetail(event.target.value);
  };

  const advancePage = () => {
    if (currentPage == numPages) {
      setCurrentPage(1);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const lastPage = () => {
    if (currentPage == 1) {
      setCurrentPage(numPages);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const retrieveTaskOrders = () => {
    TaskOrderService.getTaskOrdersForUser(currentUser.currentUser.id)
      .then((response) => {
        setTaskOrders(response.data);
      })
      .catch((error) => {
        //logout = true;
        history.push("/logout/");
      });
  };

  const generateCustomReport = () => {
    var data = {
      active: taskOrderDetail.active,
      description: taskOrderDetail.description,
      id: taskOrderDetail.id,
      number: taskOrderDetail.number,
      pubDate: taskOrderDetail.pubDate,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
    };

    PDFService.generateCustomReport(data).then((response) => {
      setReport(response.data);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      setLink(link);
    });
  };

  useEffect(() => {
    currentView.setCurrentView("Month Report");
  }, []);

  useEffect(() => {
    retrieveTaskOrders();
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const classes = useStyles();

  return (
    <>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {logout ? <Redirect to="/logout" /> : null}
        <Container maxWidth="xl" className={classes.container}>
          <div style={{ paddingTop: 10 }}>
            <Button
              onClick={() => generateCustomReport()}
              variant="contained"
              color="primary"
            >
              Preview Month Report
            </Button>
            {currentUser.currentUser.roles[0].includes("ADMIN") ||
            currentUser.currentUser.roles[0].includes("TO_LEAD") ? (
              <a
                href={link}
                download={`TO#${taskOrderDetail.number}_${new Date(
                  selectedEndDate
                ).toLocaleDateString()}.pdf`}
                style={{ paddingLeft: 10 }}
              >
                Click to download
              </a>
            ) : null}
          </div>

          <FormControl size="large">
            <InputLabel style={{ padding: 8 }} id="taskOrder">
              Task Order
            </InputLabel>
            <Select
              id="taskOrderSelect"
              value={taskOrderDetail}
              onChange={handleChange}
              style={{ minWidth: 120, padding: 8 }}
            >
              {" "}
              {taskOrders.map((task) => (
                <MenuItem value={task}>{task.number}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Card style={{ padding: 10, width: size.width / 2.5 }}>
            <CardContent>
              <div style={{ paddingBottom: 5 }}>
                <Typography
                  className={classes.title}
                  variant="h4"
                  color="textSecondary"
                >
                  Report Preview
                </Typography>
              </div>
              {report ? (
                <>
                  <div>
                    <Document
                      file={report}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      {
                        <div
                          style={{
                            width: size.width / 3.5,
                            height: size.width / 3.5 + size.height / 4,
                          }}
                        >
                          <Card raised={true}>
                            <Page
                              pageNumber={currentPage}
                              scale={size.width / 2000}
                            />
                          </Card>
                        </div>
                      }
                    </Document>

                    <div style={{ paddingTop: 40 }}>
                      Page {currentPage} of {numPages}
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => lastPage()}
                      >
                        <ArrowBackIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => advancePage()}
                      >
                        <ArrowForwardIcon fontSize="inherit" />
                      </IconButton>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </CardContent>
          </Card>
        </Container>
      </main>
    </>
  );
}
