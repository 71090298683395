import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import SubTaskOrderService from "../services/SubTaskOrderService";
import TaskOrderService from "../services/TaskOrderService";
import Button from "@material-ui/core/Button";
import { useStyles } from "./App";
import { endOfISOWeek, startOfISOWeek, subDays } from "date-fns";
import SubTaskOrderResponseService from "../services/SubTaskOrderResponseService";
import ReactTable from "./ReactTable";
import { Document, Page, pdfjs } from "react-pdf";
import PDFService from "../services/PDFService";
import useWindowSize from "./useWindowSize";
import { useView } from "../context/ViewContext";
import { useAuth } from "../context/AuthContext";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function TaskOrderDetail(props) {
  const currentUser = useAuth();
  const [taskOrderDetail, setTaskOrderDetail] = useState({});
  const [subTaskOrders, setSubTaskOrders] = useState([]);
  const [unreportedUsers, setUnreportedUsers] = useState([]);
  const [userUpdates, setUserUpdates] = useState([]);
  const [usersForTask, setUsersForTask] = useState([]);
  const [numPages, setNumPages] = useState(1);
  const [customReport] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [report, setReport] = useState();
  const [responses, setResponses] = useState([]);
  const currentView = useView();
  let newDate = subDays(new Date(), 7);
  const [link, setLink] = useState();
  const firstUpdate = useRef(true);
  let currDate = new Date();
  const currStart = startOfISOWeek(currDate, { weekStartsOn: 1 }).getTime();
  const currEnd = endOfISOWeek(currDate, { weekStartsOn: 1 }).getTime();
  const start = startOfISOWeek(newDate, { weekStartsOn: 1 }).getTime();
  const end = endOfISOWeek(newDate, { weekStartsOn: 1 }).getTime();

  const [showSubTasks, setShowSubTasks] = useState(false);
  const [showUnreportedUsers, setShowUnreportedUsers] = useState(false);
  const size = useWindowSize();

  useEffect(() => {
    currentView.setCurrentView("Task Order Detail");
  }, []);

  const retrieveTaskOrderDetail = () => {
    TaskOrderService.getTaskOrderDetail(props.match.params.id).then(
      (response) => {
        setTaskOrderDetail(response.data);
      }
    );
  };

  const retrieveSubTaskOrders = () => {
    SubTaskOrderService.getSubWithParent(props.match.params.id).then(
      (response) => {
        setSubTaskOrders(response.data);
      }
    );
  };

  const retrieveResponses = () => {
    SubTaskOrderResponseService.getResponsesForTask(props.match.params.id).then(
      (response) => {
        setResponses(response.data);
      }
    );
  };

  const advancePage = () => {
    if (currentPage == numPages) {
      setCurrentPage(1);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const lastPage = () => {
    if (currentPage == 1) {
      setCurrentPage(numPages);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const showSubTaskButton = () => {
    showSubTasks ? setShowSubTasks(false) : setShowSubTasks(true);
  };

  const showUnreportedUsersButton = () => {
    showUnreportedUsers
      ? setShowUnreportedUsers(false)
      : setShowUnreportedUsers(true);
  };

  const generateUnreportedUsers = () => {
    const data = [...userUpdates];
    const allUsers = [...usersForTask];
    userUpdates.forEach((respondedUser) => {
      allUsers.forEach((user) => {
        if (respondedUser.id == user.id) {
          allUsers.splice(allUsers.indexOf(user), 1);
        }
      });
    });
    setUnreportedUsers(allUsers);
  };

  const generateWeeklyReport = () => {
    var data = {
      active: taskOrderDetail.active,
      description: taskOrderDetail.description,
      id: taskOrderDetail.id,
      number: taskOrderDetail.number,
      pubDate: taskOrderDetail.pubDate,
      startDate: start,
      endDate: end,
    };

    PDFService.generateWeeklyReport(data).then((response) => {
      setReport(response.data)
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      setLink(link);
    });
  };

  const getUserUpdatesQuery = () => {
    var data = {
      taskOrderId: props.match.params.id,
      startDate: currStart,
      endDate: currEnd,
    };
    TaskOrderService.getUserUpdatesQuery(data).then((response) => {
      setUserUpdates(response.data);
    });
  };

  const getUsersForTask = () => {
    TaskOrderService.getUsersForTask(props.match.params.id).then((response) => {
      setUsersForTask(response.data);
      getUserUpdatesQuery();
    });
  };

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    generateWeeklyReport();
  }, [taskOrderDetail]);

  useEffect(() => {
    getUsersForTask();
  }, []);

  /*useEffect(() => {
    getUserUpdatesQuery()
  }, []);*/

  useEffect(() => {
    retrieveSubTaskOrders();
  }, []);

  useEffect(() => {
    generateUnreportedUsers();
  }, [usersForTask, userUpdates]);

  useEffect(() => {
    retrieveResponses();
  }, []);

  useEffect(() => {
    retrieveTaskOrderDetail();
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const classes = useStyles();

  const columns = React.useMemo(
    () => [
      {
        Header: "Username",
        accessor: "username",
      },

      {
        Header: "Sub Task",
        accessor: "subTaskOrderNumber",
      },
      {
        Header: "Description",
        accessor: "description",
      },

      {
        Header: "Pub Date",
        accessor: "pubDate",
        Cell: (props) => {
          return <span>{new Date(props.value).toLocaleDateString()}</span>;
        },
      },
    ],
    []
  );

  return (
    <>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Typography
            className={classes.title}
            variant="h2"
            color="textPrimary"
          >
            <b>Task Order {taskOrderDetail.number}</b>
          </Typography>
          <Typography
            className={classes.title}
            variant="h6"
            color="textSecondary"
          >
            {taskOrderDetail.description}
          </Typography>
          <div style={{ paddingBottom: 10, display: "flex" }}>
            <Button onClick={() => showSubTaskButton()} variant="contained">
              {showSubTasks ? "Hide Sub Tasks" : "Show Sub Tasks"}
            </Button>
            <div style={{ paddingLeft: 20 }}>
              <Button
                onClick={() => showUnreportedUsersButton()}
                variant="contained"
                color="primary"
              >
                {userUpdates && usersForTask
                  ? userUpdates.length + "/" + usersForTask.length
                  : "Querying"}
              </Button>
              <div style={{ "font-size": 8 }}>Reported Users</div>
            </div>
          </div>

          {showSubTasks ? (
            <Grid container spacing={2}>
              {subTaskOrders.map((subTask) => (
                <Grid item xs={4}>
                  <Link style={{ textDecoration: "none" }}>
                    <Card raised={false} className={classes.root}>
                      <CardContent>
                        <b>{subTask.number}</b>
                      </CardContent>
                      <CardContent>{subTask.description}</CardContent>
                    </Card>
                  </Link>
                </Grid>
              ))}
            </Grid>
          ) : null}
          {showUnreportedUsers ? (
            <Grid container spacing={2}>
              {unreportedUsers.map((user) => (
                <Grid item xs={4}>
                  <Link style={{ textDecoration: "none" }}>
                    <Card raised={false} className={classes.root}>
                      <CardContent>
                        {user.firstName} {user.lastName}
                      </CardContent>
                    </Card>
                  </Link>
                </Grid>
              ))}
            </Grid>
          ) : null}
          <div style={{ paddingTop: 10 }}>
            <Grid container direction="row" spacing={5}>
              <Grid item xl={6} m={5.8} s={5.5} xs={5}>
                <Card style={{ padding: 10 }}>
                  <CardContent>
                    <div style={{ paddingBottom: 5 }}>
                      <Typography
                        className={classes.title}
                        variant="h4"
                        color="textSecondary"
                      >
                        Report Preview
                      </Typography>
                      {currentUser.currentUser.roles[0].includes("ADMIN") ||  currentUser.currentUser.roles[0].includes("TO_LEAD") ? (
                      <a
                        href={link}
                        download={`TO#${taskOrderDetail.number}_${new Date(
                          end
                        ).toLocaleDateString()}.pdf`}
                        style={{ paddingTop: 10 }}
                      >
                        Click to download
                      </a>
                      ) : null}
                    </div>
                    {report ? (
                      <>
                        <div>
                          <Document
                            file={customReport ? customReport : report}
                            onLoadSuccess={onDocumentLoadSuccess}
                          >
                            {
                              <div
                                style={{
                                  width: size.width / 3.5,
                                  height: size.width / 3.5 + size.height / 4,
                                }}
                              >
                                <Card raised={true}>
                                  <Page
                                    pageNumber={currentPage}
                                    scale={size.width / 2000}
                                  />
                                </Card>
                              </div>
                            }
                          </Document>

                          <div style={{ paddingTop: 40 }}>
                            Page {currentPage} of {numPages}
                            <IconButton
                              aria-label="delete"
                              size="small"
                              onClick={() => lastPage()}
                            >
                              <ArrowBackIcon fontSize="inherit" />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              size="small"
                              onClick={() => advancePage()}
                            >
                              <ArrowForwardIcon fontSize="inherit" />
                            </IconButton>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xl={6} m={6.2} s={6.5} xs={7}>
                <ReactTable columns={columns} data={responses}></ReactTable>
              </Grid>
            </Grid>
          </div>
        </Container>
      </main>
    </>
  );
}
