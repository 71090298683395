import { useTheme } from "@material-ui/core/styles";
import { endOfISOWeek, startOfISOWeek, subDays } from "date-fns";
import { Redirect, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import DashboardService from "../services/DashboardService";
import Title from "./Title";

export default function Chart() {
  const theme = useTheme();
  const [updates, setUpdates] = useState();
  const history = useHistory();
  let date = new Date();
  let newDate = subDays(date, 7);
  const start = startOfISOWeek(newDate, { weekStartsOn: 1 }).getTime();
  const end = endOfISOWeek(newDate, { weekStartsOn: 1 }).getTime();
  const [logout, setLogout] = useState(false);

  const getTaskUpdateCounts = () => {
    let data = {
      startDate: start,
      endDate: end,
    };

    DashboardService.getUpdateGraph(data)
      .then((response) => {
        if (response.status == 200) setUpdates(response.data);
      })
      .catch((error) => {
        //logout = true;
        history.push("/logout/")
      });
  };

  useEffect(() => {
    var today = Date.now();
    getTaskUpdateCounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title>Last Week Task Order Updates</Title>
      {logout ? <Redirect to="/logout" /> : null}

      <ResponsiveContainer>
        {updates ? (
          <BarChart
            data={updates}
            margin={{
              top: 16,
              right: 16,
              bottom: 0,
              left: 24,
            }}
          >
            <XAxis dataKey="taskOrder" stroke={theme.palette.text.secondary}>
              <Label
                dy={10}
                style={{
                  textAnchor: "middle",
                  fill: theme.palette.text.primary,
                }}
              >
                Task Order #
              </Label>
            </XAxis>
            <YAxis stroke={theme.palette.text.secondary}>
              <Label
                angle={270}
                position="left"
                style={{
                  textAnchor: "middle",
                  fill: theme.palette.text.primary,
                }}
              >
                # of Updates
              </Label>
            </YAxis>
            <Tooltip label="Task Update" />
            <Bar dataKey="count" fill="#f3c363" />
          </BarChart>
        ) : (
          <></>
        )}
      </ResponsiveContainer>
    </>
  );
}
