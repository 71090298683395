import React from "react";
import { useAuth } from "../context/AuthContext";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { Link } from "react-router-dom";
import ListSubheader from "@material-ui/core/ListSubheader";

export default function AdminSideItems(props) {
  const currentUser = useAuth();

  return (
    <>
      {/*if user has admin priveleges they get special routes, otherwise this component returns nothing*/}
      {currentUser.currentUser ? (
        currentUser.currentUser.roles[0].includes("ADMIN") ? (
          <>
            <List>
              <ListSubheader inset>Admin Pages</ListSubheader>
              <Link
                to={"/adminTaskOrderView"}
                onClick={() => props.close()}
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Task Orders" />
                </ListItem>
              </Link>
              <Link
                to={"/adminUserView/"}
                onClick={() => props.close()}
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <AssignmentLateIcon />
                  </ListItemIcon>
                  <ListItemText primary="Users" />
                </ListItem>
              </Link>
            </List>
          </>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </>
  );
}
