import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useStyles } from "./App";
import { useAuth } from "../context/AuthContext";
import UserService from "../services/UserService";
import { useView } from "../context/ViewContext";
import ReactTable from "./ReactTable";
import TaskOrderService from "../services/TaskOrderService";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

export default function MyProfile() {
  const currentUser = useAuth();
  const [userDetail, setUserDetail] = useState();
  const [error, setError] = useState("");
  const currentView = useView();
  const [responses, setResponses] = useState([]);
  //const [loading, setLoading] = useState(false);
  const history = useHistory();

  const classes = useStyles();

  const getUserInfo = () => {
    currentUser.currentUser
      ? UserService.getUserInfo(currentUser.currentUser.id).then((response) => {
          setUserDetail(response.data);
        })
      : setUserDetail(null);
  };

  useEffect(() => {
    currentView.setCurrentView("My Profile");
  }, []);

  useEffect(() => {
    getUserInfo();
  }, []);

  const retrieveResponses = () => {
    TaskOrderService.getResponsesForUser(currentUser.currentUser.id).then(
      (response) => {
        setResponses(response.data);
      }
    );
  };

  useEffect(() => {
    retrieveResponses();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Description",
        accessor: "description",
      },

      {
        Header: "Pub Date",
        accessor: "pubDate",
        Cell: (props) => {
          return <span>{new Date(props.value).toLocaleDateString()}</span>;
        },
      },
      {
        Header: "Edit Response",
        accessor: "id",
        Cell: (props) => {
          return (
            <>
              <Link
                to={{
                  pathname: `/editResponse/${props.data[props.row.id].id}`,
                  state: props.data[props.row.id],
                }}
              >
                <KeyboardArrowRightIcon style={{ color: "gray" }} />
              </Link>
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <main className={classes.content} style={{ padding: 10 }}>
        <div className={classes.appBarSpacer} />
        {userDetail ? (
          <>
            <div>
              <p>
                <b>First Name: </b>
                {userDetail.firstName}
              </p>
            </div>
            <div>
              <p>
                <b>Last Name: </b>
                {userDetail.lastName}
              </p>
            </div>
            <div>
              <p>
                <b>Username: </b>
                {userDetail.username}
              </p>
            </div>
            <div>
              <p>
                <b>Email: </b>
                {userDetail.email}
              </p>
            </div>
            <Link style={{ textDecoration: "none" }} to="/changePassword">
              <Button variant="contained" color="primary">
                Change Password
              </Button>
            </Link>
            <div>
              <h2>My Updates</h2>

              <ReactTable columns={columns} data={responses}></ReactTable>
            </div>
          </>
        ) : (
          <></>
        )}
      </main>
    </>
  );
}
