import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormGroup,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useStyles } from "./App";
import { useAuth } from "../context/AuthContext";
import UserService from "../services/UserService";
import Swal from "sweetalert2"
import { useView } from "../context/ViewContext"

export default function PasswordChange() {
  const currentUser = useAuth();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const [userDetail, setUserDetail] = useState();
  const [error, setError] = useState("");
  const currentView = useView();
  //const [loading, setLoading] = useState(false);
  const history = useHistory();

  const classes = useStyles();

  useEffect(() => {
    currentView.setCurrentView("Password Change");
  }, []);

  const saveInfo = () => {
    if (passwordRef.current.value != confirmPasswordRef.current.value) {
      setError("Passwords are not the same");
    } else if (passwordRef.current.value.length < 7) {
      setError("Password must be at least 8 characters long")
    } else {
      var data = {
        id: currentUser.currentUser.id,
        newPassword: passwordRef.current.value,
      };

      UserService.changePassword(data).then((response) => {
          if (response.data.message == "Password changed") {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "Password has been changed",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Error!",
              text: "Password has not been changed",
            });
          }
      });
    }
  };

  return (
    <>
      <main className={classes.content} style={{ padding: 10 }}>
        <div className={classes.appBarSpacer} />
        <Card>
          <CardContent>
            <h2 className="text-center mb-4">Change Password</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <FormControl>
              <FormGroup id="password">
                <TextField
                  id="password"
                  label="New Password"
                  inputRef={passwordRef}
                  required
                />
              </FormGroup>
              <FormGroup id="confirmPassword">
                <TextField
                  id="confirmPassword"
                  label="Confirm Password"
                  inputRef={confirmPasswordRef}
                  required
                />
              </FormGroup>
              <div style={{ paddingTop: 10 }}></div>
              <Button
                onClick={() => saveInfo()}
                variant="contained"
                color="primary"
                className="w-100"
                type="submit"
              >
                Save
              </Button>
            </FormControl>
          </CardContent>
        </Card>
      </main>
    </>
  );
}
