import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import clsx from "clsx";
import React, { useEffect } from "react";
import ActiveTaskOrders from "./ActiveTaskOrderView";
import { useStyles } from "./App";
import Chart from "./Chart";
import Updates from "./Updates";
import { useView } from "../context/ViewContext";
import Assignment from "@material-ui/icons/Assignment";
import Title from "./Title";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      Warfighter Readiness Research Division&nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Dashboard() {
  const classes = useStyles();
  const currentView = useView();

  useEffect(() => {
    currentView.setCurrentView("Task Order Dashboard");
  }, []);

  /*const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };*/
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8} lg={8}>
              <Paper className={fixedHeightPaper}>
                <Chart />
              </Paper>
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <div>
                <Link to="/weeklyForm" style={{ textDecoration: "none" }}>
                  <Paper className={fixedHeightPaper}>
                    <Title>Weekly Form</Title>

                    <div
                      style={{
                        width: "auto",
                        height: "auto",
                        margin: "auto",
                        padding: "10px",
                        position: "relative",
                      }}
                    >
                      <Assignment
                        fontSize="inherit"
                        style={{ color: "gray", fontSize: "100px" }}
                      />
                    </div>
                  </Paper>
                </Link>
              </div>
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <Paper className={fixedHeightPaper}>
                <ActiveTaskOrders />
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </>
  );
}
