import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState, useRef } from "react";
import { useStyles } from "../App";
import TextField from "@material-ui/core/TextField";
import SubTaskOrderService from "../../services/SubTaskOrderService";
import Checkbox from "@material-ui/core/Checkbox";
import EditIcon from '@material-ui/icons/Edit';
import { useView } from "../../context/ViewContext";
import Button from "@material-ui/core/Button";

export default function SubTaskOrderDetail(props) {
  const [subTasksOrderDetail, setSubTasksOrderDetail] = useState();
  const [active, setActive] = useState(true)
  const description = useRef();
  const classes = useStyles();
  const [header, setHeader] = useState("");
  const [editHeader, setEditHeader] = useState(false);
  const [temp, setTemp] = useState("")

  const currentView = useView();

  const editTitle = () => {
    editHeader ? setEditHeader(false) : setEditHeader(true);
  };

  const save = () => {
    editHeader ? setEditHeader(false) : setEditHeader(true);
    setHeader(temp)

    // make api call to update database
    let data = {
      id: subTasksOrderDetail.id,
      number: temp,
      active: subTasksOrderDetail.active,
      description: description.current.value,
      pubDate: subTasksOrderDetail.pubDate,
    }

    SubTaskOrderService.updateSubTitle(data).then((response) => {
    })
    
  }

  const handleHeaderChange = event => {
    setTemp(event.target.value);

  };

  const getSubTaskOrderDetails = () => {
    SubTaskOrderService.getSubTaskOrderDetails(props.match.params.id).then(
      (response) => {
          setActive(response.data.active)
        setSubTasksOrderDetail(response.data);
        setHeader(response.data.number)
        
      }
    );
  };

  const saveDescription = () => {
    let data = {
      id: subTasksOrderDetail.id,
      active: subTasksOrderDetail.active,
      description: description.current.value,
      pubDate: subTasksOrderDetail.pubDate,
      number: subTasksOrderDetail.number,
    };
    SubTaskOrderService.updateDescription(data).then((response) => {
    });
  };

  const saveActive = () => {
    let data = {
      id: subTasksOrderDetail.id,
      active: active,
      saveDescription: subTasksOrderDetail.description,
      pubDate: subTasksOrderDetail.pubDate,
      number: subTasksOrderDetail.number,
    };
    SubTaskOrderService.updateActive(data).then((response) => {
    });
  };

  const handleChange = (event) => {
    active
      ? setActive(false)
      : setActive(true)
  };

  useEffect(() => {
    getSubTaskOrderDetails();
  }, []);

  useEffect(() => {
    currentView.setCurrentView("Sub Task Order Detail");
  }, []);

  return (
    <>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Grid>
            <Card>
            <CardContent>
            {editHeader ? (
              <h1 style={{ padding: 0 }}>
                <TextField onChange={handleHeaderChange} defaultValue={header} />
              </h1>
            ) : (
              <h1 style={{ padding: 0 }}>{header}</h1>
            )}

            {editHeader ? (
              <div style={{ float: "left", display: "inline" }}>
                <Button style={{ marginBottom: "10px" }} onClick={() => editTitle()}>
                  <EditIcon style={{ marginRight: "10px" }} />
                  Edit
                </Button>
                <Button
                  style={{ marginLeft: "10px", marginBottom: "10px" }}
                  variant="contained"
                  color="primary"
                  onClick={() => save()}
                >
                  Save
                </Button>
              </div>
            ) : (
              <div style={{ float: "left", display: "inline" }}>
                <Button style={{marginBottom: "10px"}} onClick={() => editTitle()}>
                  <EditIcon style={{ marginRight: "10px" }} />
                  Edit
                </Button>
              </div>
            )}
          </CardContent>
            </Card>

            <Grid
              container
              direction="row"
              spacing="2"
              style={{ paddingTop: 20 }}
            >
              <Grid item xs={6}>
                <Card style={{ height: 300 }}>
                  <CardContent>
                    <h2>Description</h2>
                    {subTasksOrderDetail ? (
                      <TextField
          
                        multiline
                        style={{width: 400}}
                        rows={4}
                        inputRef={description}
                        placeholder={subTasksOrderDetail.description}
                        defaultValue={subTasksOrderDetail.description}
                        variant="outlined"
                      />
                    ) : null}

                    <div style={{paddingTop: 30}}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => saveDescription()}
                      >
                        Save
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={6}>
                <Card style={{ height: 210 }}>
                  <CardContent>
                    <h2>Active</h2>
                  
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >  <Checkbox
                        checked={active}
                        onChange={handleChange}
                      /> 
                        
                      </div>
                    

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      <span style={{ paddingTop: 20 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => saveActive()}
                        >
                          Save
                        </Button>
                      </span>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </main>
    </>
  );
}
