import { FormControl, FormLabel } from "@material-ui/core/";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { formatISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { useAuth } from "../context/AuthContext";
import { useView } from "../context/ViewContext";
import SubTaskOrderResponseService from "../services/SubTaskOrderResponseService";
import SubTaskOrderService from "../services/SubTaskOrderService";
import TaskOrderService from "../services/TaskOrderService";
import { useStyles } from "./App";

export default function WeeklyForm() {
  const currentUser = useAuth();
  const [taskOrders, setTaskOrders] = useState([]);
  const [currentTask, setCurrentTask] = useState();
  const [subTasks, setSubTask] = useState([]);
  const [responses, setResponses] = useState({});
  const currentView = useView();
  const history = useHistory();
  const [logout, setLogout] = useState(false);

  useEffect(() => {
    currentView.setCurrentView("Weekly Form");
  }, []);

  const handleSubmit = () => {
    try {
      for (const [key, value] of Object.entries(responses)) {
        var date = Date.now();
        var time = formatISO(date);

        var data = {
          subTaskOrder: key,
          pubDate: time,
          description: value,
          userId: currentUser.currentUser.id,
        };

        SubTaskOrderResponseService.addResponse(data).then((response) => {});
      }
    } catch {
      Swal.fire({
        icon: "error",
        title: "uh oh",
        text: "Entry unsuccessful!",
      });
    } finally {
      setResponses();
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Updates have been submitted",
      }); //.then(setResponses({}));
    }
    setResponses({});
  };

  const handleInput = (event, id) => {
    responses[id] = event.target.value;
  };

  const handleChange = (event) => {
    setResponses();
    setCurrentTask(event.target.value);
  };

  const retrieveTaskOrders = () => {
    TaskOrderService.getActiveTaskOrdersForUser(currentUser.currentUser.id)
      .then((response) => {
        setTaskOrders(response.data);
        setCurrentTask(response.data[0]);
      })
      .catch((error) => {
        //setLogout(true)
        history.push("/logout/");
      });
  };

  const retrieveSubTaskOrders = () => {
    setResponses({});
    if (currentTask)
      SubTaskOrderService.getSubWithParent(currentTask.id).then((response) => {
        setSubTask(response.data);
      });
  };

  useEffect(() => {
    retrieveSubTaskOrders();
    setResponses({});
  }, [currentTask]);

  useEffect(() => {
    retrieveTaskOrders();
  }, []);

  const classes = useStyles();

  return (
    <>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {logout ? <Redirect to="/logout" /> : null}
        <Container maxWidth="xl" className={classes.container}>
          <h1>
            Task Order&emsp;&emsp;&emsp;
            {currentTask ? (
              <Select
                labelId="demo-simple-select-placeholder-label-label"
                id="demo-simple-select-placeholder-label"
                value={currentTask}
                defaultValue="Select Different Task"
                onChange={handleChange}
                className={classes.selectEmpty}
              >
                {taskOrders.map((task) => (
                  <MenuItem value={task}>
                    <b>{task.number}</b>
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <></>
            )}
          </h1>

          {currentTask ? (
            <FormControl fullWidth="true">
              <FormLabel>
                <h3>{currentTask.description}</h3>

                <i>
                  Separate multiple updates in the same section by a single line
                  break
                </i>
              </FormLabel>

              {subTasks.map((task, index) => (
                <>
                  <TextField
                    id="standard-textarea"
                    value={responses ? responses[task.id] : ""}
                    label={task.number}
                    onChange={(event, value) => handleInput(event, task.id)}
                    placeholder="Please enter task order updates"
                    multiline
                    style={{ paddingBottom: 10 }}
                  />
                  <FormLabel>{task.description}</FormLabel>
                </>
              ))}
              <div style={{ paddingTop: 30 }}></div>

              <Button
                onClick={() => handleSubmit()}
                size="large"
                color="primary"
                variant="contained"
              >
                Submit
              </Button>
            </FormControl>
          ) : (
            <></>
          )}
        </Container>
      </main>
    </>
  );
}
