import Container from "@material-ui/core/Container";
import React, { useEffect, useState } from "react";
import { useStyles } from "../App";
import { Link } from "react-router-dom";
import ReactTable from "../ReactTable";
import { useView } from "../../context/ViewContext";
import TaskOrderService from "../../services/TaskOrderService";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import BlockIcon from "@material-ui/icons/Block";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Button } from "@material-ui/core";

export default function AdminTaskOrderView(props) {
  const [taskOrders, setTaskOrders] = useState([]);
  const currentView = useView();

  const getUsers = () => {
    TaskOrderService.getAll().then((response) => {
      setTaskOrders(response.data);
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    currentView.setCurrentView("Admin Task Order View");
  }, []);

  const classes = useStyles();

  const columns = React.useMemo(
    () => [
      {
        Header: "Number",
        accessor: "number",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Active",
        accessor: "active",
        Cell: (props) => {
          return (
            <>
              {props.data[props.row.id].active == true ? (
                <CheckCircleIcon color="primary" />
              ) : (
                <BlockIcon color="secondary" />
              )}
            </>
          );
        },
      },

      {
        Header: "View Task Order",
        accessor: "id",
        Cell: (props) => {
          return (
            <>
              <Link
                to={{
                  pathname: `/adminTaskOrderView/${
                    props.data[props.row.id].id
                  }`,
                  state: props.data[props.row.id],
                }}
              >
                <KeyboardArrowRightIcon style={{ color: "gray" }} />
              </Link>
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Link style={{ textDecoration: "none" }} to="/createTaskOrder">
            <Button variant="contained" color="primary">
              Create Task Order
            </Button>
          </Link>

          <ReactTable columns={columns} data={taskOrders}></ReactTable>
        </Container>
      </main>
    </>
  );
}
