import Container from "@material-ui/core/Container";
import React, { useEffect, useState, useRef } from "react";
import { useStyles } from "../App";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { useView } from "../../context/ViewContext"
import ReactTable from "../ReactTable";
import UserService from "../../services/UserService";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

export default function UserView(props) {
  const [users, setUsers] = useState([]);
  const currentView = useView();

  const getUsers = () => {
    UserService.getAllUsers().then((response) => {
      setUsers(response.data)
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    currentView.setCurrentView("Admin User View");
  }, []);

  const classes = useStyles();


  const columns = React.useMemo(
    () => [
      {
        Header: "Username",
        accessor: "username",
      
      
      },
      {
        Header: "Email",
        accessor: "email",
        
      },
      {
        Header: "First Name",
        accessor: "firstName",
       
      },

      {
        Header: "Last Name",
        accessor: "lastName",
       
      },
      {
        Header: "Company",
        accessor: "companyName",
      },

      {
        Header: "View User",
        accessor: "id",
        Cell: (props) => {
          return (
            <>
               <Link to={{ pathname: `/userDetail/${props.data[props.row.id].id}`, state: props.data[props.row.id]}} >
                <KeyboardArrowRightIcon style={{color: 'gray'}} />
              </Link>
            </>
          );
        },
      },

    ],
    []
  );

  return (
    <>
      <main className={classes.content}>
        <Button></Button>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
        <Link style={{textDecoration: "none"}} to="/createUser"><Button variant="contained" color="primary">Create New User</Button></Link>
         
            <ReactTable columns={columns} data={users}></ReactTable>
          
        </Container>
      </main>
    </>
  );
}
