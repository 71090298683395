import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import BarChartIcon from "@material-ui/icons/BarChart";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ListAltIcon from "@material-ui/icons/ListAlt";
import React from "react";
import { Link } from "react-router-dom";
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import DateRangeIcon from '@material-ui/icons/DateRange';

export default function SideItems(props) {

  return (
    <>
      <List>
        <Link
          to={"/home"}
          onClick={() => props.close()}
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItem button>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        </Link>
        <Link
          to={"/taskOrders/"}
          onClick={() => props.close()}
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItem button>
            <ListItemIcon>
              <AssignmentLateIcon />
            </ListItemIcon>
            <ListItemText primary="Task Orders" />
          </ListItem>
        </Link>
        <Link
          to={"/weeklyForm/"}
          onClick={() => props.close()}
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItem button>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Weekly Form" />
          </ListItem>
        </Link>
        <Link
          to={"/weeklyReport/"}
          onClick={() => props.close()}
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItem button>
            <ListItemIcon>
              <ViewWeekIcon />
            </ListItemIcon>
            <ListItemText primary="Weekly Reports" />
          </ListItem>
        </Link>
        <Link
          to={"/customReport/"}
          onClick={() => props.close()}
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItem button>
            <ListItemIcon>
              <DateRangeIcon />
            </ListItemIcon>
            <ListItemText primary="Custom Reports" />
          </ListItem>
        </Link>
      </List>
    </>
  );
}
