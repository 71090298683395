import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import React, { useEffect, useState } from "react";
import { useView } from "../context/ViewContext";
import UserService from "../services/UserService";
import { useStyles } from "./App";

export default function UserView(props) {
  const [users, setUsers] = useState([]);
  const currentView = useView();

  const getUsers = () => {
    UserService.getAllUsers().then((response) => {
      setUsers(response.data);
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    currentView.setCurrentView("Edit Response");
  }, []);

  const classes = useStyles();

  return (
    <>
      <main className={classes.content}>
        <Button></Button>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}></Container>
      </main>
    </>
  );
}
