import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useState } from "react";
import aptima from "../../assets/Aptima.png";
import ball from "../../assets/Ball.png";
import cae from "../../assets/CAE.png";
import cubic from "../../assets/Cubic.png";
import leidos from "../../assets/Leidos.png";
import rcg from "../../assets/RCG.png";
import { useView } from "../../context/ViewContext";
import TaskOrderService from "../../services/TaskOrderService";
import UserService from "../../services/UserService";
import { useStyles } from "../App";

import List from "@material-ui/core/List";

import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Swal from "sweetalert2";

export default function UserDetail(props) {
  const [roles, setRoles] = useState(
    props.location.state.roles ? props.location.state.roles.split(",") : []
  );

  const [archived, setArchived] = useState(props.location.state.archived);

  const [userRoles, setUserRoles] = [];

  const [logo, setLogo] = useState();
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState();
  const [right, setRight] = useState();
  const [uleft, setULeft] = useState();
  const [uright, setURight] = useState();
  const currentView = useView();
  const [companies, setCompanies] = useState([]);
  const [editUser, setEditUser] = useState(false);
  const [username, setUsername] = useState(props.location.state.username);
  const [firstName, setFirstName] = useState(props.location.state.firstName);
  const [lastName, setLastName] = useState(props.location.state.lastName);
  const [email, setEmail] = useState(props.location.state.email);
  const [company, setCompany] = useState(props.location.state.companyName);

  useEffect(() => {
    currentView.setCurrentView("Admin User Detail");
  }, []);

  const classes = useStyles();

  const [uchecked, setUChecked] = useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const uleftChecked = intersection(uchecked, uleft);
  const urightChecked = intersection(uchecked, uright);

  const editUserButton = () => {
    editUser ? setEditUser(false) : setEditUser(true);
  };
  const handleCompanyChange = (event) => {
    setCompany(event.target.value);
  };

  const submitUserChanges = () => {
    let data = {
      id: props.location.state.id,
      firstName: firstName,
      lastName: lastName,
      email: email,
      username: username,
      companyId: company.id,
    };

    UserService.updateUser(data).then((response) => {});
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const archiveUser = () => {
    // make api call to id
    if (archived) {
      UserService.unarchiveUser(props.location.state.id).then((response) => {
        setArchived(false);
      });
    } else {
      UserService.archiveUser(props.location.state.id).then((response) => {
        setArchived(true);
      });
    }
  };

  function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  function union(a, b) {
    return [...a, ...not(b, a)];
  }

  const resetPassword = () => {
    try {
      UserService.resetPassword(props.location.state.id).then((response) => {});
    } catch {
      Swal.fire({
        icon: "error",
        title: "uh oh",
        text: "Password reset unsuccessful!",
      });

      return;
    } finally {
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: 'Password has been reset to "art"',
      });
    }
  };

  const getActiveTaskOrders = () => {
    var assigned = [];
    var unassigned = [];

    TaskOrderService.getActiveTaskOrders(props.location.state.id).then(
      (response) => {
        if (roles.length == 0) {
          setLeft(response.data);
          setRight([]);
          return;
        }
        response.data.map((task) => {
          if (roles.includes(task.id.toString())) {
            assigned.push(task);
          } else {
            unassigned.push(task);
          }
        });
        setLeft(unassigned);
        setRight(assigned);
      }
    );
  };

  const handleUToggle = (value) => () => {
    const currentIndex = uchecked.indexOf(value);
    const newChecked = [...uchecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setUChecked(newChecked);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const save = () => {
    UserService.saveUserAssignments(props.location.state.id, right).then(
      (response) => {
        if (response.data == "Success") {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "User Tasks Assignments have been updated!",
          });
        }
      }
    );
  };

  const saveUserRole = () => {
    UserService.saveUserRoles(props.location.state.id, uright).then(
      (response) => {
        if (response.data == "Success") {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "User Role Assignments have been updated!",
          });
        }
      }
    );
  };

  const getUserRoles = () => {
    var assigned = [];
    var unassigned = [
      { id: 1, name: "ADMIN" },
      { id: 2, name: "TO_LEAD" },
      { id: 3, name: "USER" },
      { id: 4, name: "COMPANY_LEAD" },
    ];

    UserService.getUserRoles(props.location.state.id).then((response) => {
      response.data.map((role) => {
        if (unassigned.some((obj) => obj.id === role.id)) {
          assigned.push(role);
          unassigned = unassigned.filter((obj) => obj.id != role.id);
        }
      });

      setULeft(unassigned);
      setURight(assigned);
    });
  };

  const getCompanies = () => {
    UserService.getCompanies().then((response) => {
      setCompanies(response.data);
    });
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleUToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setUChecked(not(uchecked, items));
    } else {
      setUChecked(union(uchecked, items));
    }
  };

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedURight = () => {
    setURight(uright.concat(uleftChecked));
    setULeft(not(uleft, uleftChecked));
    setUChecked(not(checked, uleftChecked));
  };

  const handleCheckedULeft = () => {
    setULeft(uleft.concat(urightChecked));
    setURight(not(uright, urightChecked));
    setUChecked(not(uchecked, urightChecked));
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ "aria-label": "all items selected" }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />

      <List className={classes.list} dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={value.id}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`Task Order ${value.number}`}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  const customUList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleUToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ "aria-label": "all items selected" }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />

      <List className={classes.list} dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={value.id}
              role="listitem"
              button
              onClick={handleUToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={uchecked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${value.name.replaceAll("_", " ")}`}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  useEffect(() => {
    getUserRoles();
  }, []);
  useEffect(() => {
    getActiveTaskOrders();
  }, []);

  useEffect(() => {
    let company = props.location.state.companyName;

    switch (company) {
      case "CAE":
        setLogo(cae);
        break;
      case "Leidos":
        setLogo(leidos);
        break;
      case "Ball":
        setLogo(ball);
        break;
      case "RCG":
        setLogo(rcg);
        break;
      case "Aptima":
        setLogo(aptima);
        break;
      case "Cubic":
        setLogo(cubic);
        break;
      default:
        setLogo(cae);
    }
  }, []);

  return (
    <>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Card>
            <CardContent>
              {editUser ? (
                <>
                  <div>
                    <h1 style={{ padding: 0 }}>
                      <TextField
                        onChange={handleFirstNameChange}
                        defaultValue={firstName}
                      />{" "}
                      <TextField
                        onChange={handleLastNameChange}
                        defaultValue={lastName}
                      />
                      <span style={{ float: "right" }}>
                        <img src={logo} width={logo == ball ? 100 : 200} />
                      </span>
                    </h1>
                  </div>
                  <div>
                    <b>Username: </b>{" "}
                    <TextField
                      onChange={handleUsernameChange}
                      defaultValue={username}
                    />
                  </div>
                  <div>
                    <b>Email: </b>{" "}
                    <TextField
                      onChange={handleEmailChange}
                      defaultValue={email}
                    />
                  </div>{" "}
                  <h3>Company</h3>
                  <Select
                    id="companySelect"
                    value={company}
                    onChange={handleCompanyChange}
                    style={{ minWidth: 120, padding: 8 }}
                  >
                    {" "}
                    {companies.map((comp) => (
                      <MenuItem value={comp}>{comp.name}</MenuItem>
                    ))}
                  </Select>
                </>
              ) : (
                <>
                  <div>
                    <h1 style={{ padding: 0 }}>
                      {firstName} {lastName}
                      <span style={{ float: "right" }}>
                        <img src={logo} width={logo == ball ? 100 : 200} />
                      </span>
                    </h1>
                  </div>
                  <div>
                    <b>Username: </b> {username}
                  </div>
                  <div>
                    <b>Email: </b> {email}
                  </div>{" "}
                </>
              )}
            </CardContent>
            <CardContent>
              <Button
                onClick={() => resetPassword()}
                color="primary"
                variant="contained"
              >
                Reset Password
              </Button>

              <Button
                onClick={() => archiveUser()}
                style={{ marginLeft: "10px" }}
                color={archived ? "default" : "secondary"}
                variant="contained"
              >
                {archived ? "Activate User" : "Disable User"}
              </Button>
              <Button
                onClick={() => editUserButton()}
                variant="contained"
                style={{ marginLeft: "10px" }}
              >
                <EditIcon />
              </Button>
              {editUser ? (
                <>
                  <Button
                    onClick={() => submitUserChanges()}
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: "10px" }}
                  >
                    Submit
                  </Button>
                </>
              ) : null}
            </CardContent>
          </Card>

          <div style={{ paddingTop: 20 }}>
            <Card>
              <CardContent>
                <h1>Task Order Assignments</h1>
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                  className={classes.root}
                >
                  <Grid item>
                    {left ? customList("Unassigned Task Orders", left) : null}
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" alignItems="center">
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                      >
                        &gt;
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                      >
                        &lt;
                      </Button>
                      <div style={{ paddingTop: 10 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => save()}
                        >
                          Save
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item>
                    {right ? customList("Assigned Task Orders", right) : null}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <div style={{ paddingTop: 20 }}>
              <Card>
                <CardContent>
                  <h1>User Role Assignment</h1>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    className={classes.root}
                  >
                    <Grid item>
                      {uleft
                        ? customUList("Unassigned User Roles", uleft)
                        : null}
                    </Grid>
                    <Grid item>
                      <Grid container direction="column" alignItems="center">
                        <Button
                          variant="outlined"
                          size="small"
                          className={classes.button}
                          onClick={handleCheckedURight}
                          disabled={uleftChecked.length === 0}
                          aria-label="move selected right"
                        >
                          &gt;
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          className={classes.button}
                          onClick={handleCheckedULeft}
                          disabled={urightChecked.length === 0}
                          aria-label="move selected left"
                        >
                          &lt;
                        </Button>
                        <div style={{ paddingTop: 10 }}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => saveUserRole()}
                          >
                            Save
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item>
                      {uright
                        ? customUList("Assigned User Role", uright)
                        : null}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </div>
        </Container>
      </main>
    </>
  );
}
