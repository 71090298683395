import React from "react";
import { Route } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import UserView from "./Admin/UserView";
import UserDetail from "./Admin/UserDetail";
import AdminTaskOrderView from "./Admin/AdminTaskOrderView"
import AdminTaskOrderDetail from "./Admin/AdminTaskOrderDetail";
import CreateTaskOrder from "./Admin/CreateTaskOrder"
import CreateSubTaskOrder from "./Admin/CreateSubTaskOrder"
import SubTaskOrderDetail from "./Admin/SubTaskOrderDetail"
import CreateUser from "./Admin/CreateUser"

export default function AdminSideItems(props) {
  const currentUser = useAuth();

  return (
    <>
      {/*if user has admin priveleges they get special routes, otherwise this component returns nothing*/}
      {currentUser.currentUser ? (
        currentUser.currentUser.roles[0].includes("ADMIN") ? (
          <>
            <Route path="/adminUserView/" component={UserView} />
            <Route path="/userDetail/:id" component={UserDetail} />
            <Route exact path="/adminTaskOrderView/" component={AdminTaskOrderView} />
            <Route exact path="/adminTaskOrderView/:id" component={AdminTaskOrderDetail} />
            <Route exact path="/createTaskOrder/" component={CreateTaskOrder} />
            <Route exact path="/createSubTaskOrder/" component={CreateSubTaskOrder} />
            <Route exact path="/createUser/" component={CreateUser} />
            <Route path="/subTaskOrderDetail/:id" component={SubTaskOrderDetail} />
            
          </>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </>
  );
}
