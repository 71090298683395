import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "../assets/images.png";
import { useAuth } from "../context/AuthContext"
import { useView } from "../context/ViewContext"
import SecondaryListItems from "./SecondaryListItems";
import SideItems from "./SideItems";
import AdminSideItems from "./AdminSideItems"

export default function Sidebar(props) {
  const classes = props.useStyles();
  const currentUser = useAuth()
  const currentView = useView()
  const [open, setOpen] = React.useState(true);
  const view = useView()
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={clsx(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {currentView.currentView}
            </Typography>
            {currentUser.currentUser ? ( <Link style={{paddingRight: 10, color: 'white'}} to={`/myProfile/`}>{currentUser.currentUser.username}</Link> ) : ( <></> ) }
            <img src={Logo} alt="Not found" style={{ width: 50 }} />
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <SideItems open={open} close={handleDrawerClose} />
          <Divider />
          <AdminSideItems close={handleDrawerClose} />
          <Divider />
          <SecondaryListItems
            open={open}
            close={handleDrawerClose}
          ></SecondaryListItems>
        </Drawer>
      </div>
    </>
  );
}
