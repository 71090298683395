import http from "../http-common";

class SubTaskOrderResponseService {

  getAll() {
    return http.get('/responses/')
  }

  addResponse(data) {
    return http.post('/addTaskOrderResponse/', data)
  }

  getUpdates(data) {
    return http.get('/getRecentUpdates/', data)
  }

  getResponsesForTask(id) {
    return http.get(`/responses/${id}`)
  }

}

export default new SubTaskOrderResponseService();