import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormGroup,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import Logo from "../assets/images.png";
import { useAuth } from "../context/AuthContext";
import { useView } from "../context/ViewContext";
import { useStyles } from "./App";

export default function Login() {
  const { login } = useAuth();
  const currentUser = useAuth();
  const usernameRef = useRef();
  const passwordRef = useRef();
  const [error, setError] = useState("");
  const currentView = useView();
  //const [loading, setLoading] = useState(false);
  const history = useHistory();

  const classes = useStyles();

  useEffect(() => {
    currentView.setCurrentView("Login");
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      //setLoading(true);

      var data = {
        username: usernameRef.current.value,
        password: passwordRef.current.value,
      };

      login(data).then((response) => {
        if (response.status === 200) {
          if (response.data.archived == true) {
            setError(
              "Your account has been disabled. Contact your manager for further details."
            );
          } else if (response.data.defaultPassword == true) {
            history.push("/defaultPasswordChange");
          } else if (response.data) {
          } else {
            history.push("/home");
          }
        } else if (response == "Request failed with status code 401") {
          setError("Username/Password is incorrect");
        }
      });
    } catch (e) {
      setError("Failed to log in");
    }

    //setLoading(false);
  }

  return (
    <>
      {currentUser.currentUser ? <Redirect to="/home" /> : <></>}
      <main className={classes.content} style={{ padding: 10 }}>
        <div className={classes.appBarSpacer} />
        <Card>
          <CardContent>
            <img src={Logo} alt="Not found" style={{ width: 200 }} />
            <h2 className="text-center mb-4">Log In</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <form onSubmit={handleSubmit}>
              <FormControl>
                <FormGroup id="username">
                  <TextField
                    id="username"
                    label="Username"
                    inputRef={usernameRef}
                    required
                  />
                </FormGroup>
                <FormGroup id="password">
                  <TextField
                    id="password"
                    label="Password"
                    type="password"
                    inputRef={passwordRef}
                    required
                  />
                </FormGroup>
                <div style={{ paddingTop: 10 }}></div>
                <Button
                  //onClick={() => handleSubmit()}
                  variant="contained"
                  color="primary"
                  className="w-100"
                  type="submit"
                >
                  Log In
                </Button>
              </FormControl>
            </form>
          </CardContent>
        </Card>
      </main>
    </>
  );
}
