import http from "../http-pdf";

class PDFService {
  generateWeeklyReport(data) {
    return http.post("/generateWeeklyReport/", data);
  }

  generateCustomReport(data) {
    return http.post("/generateCustomReport/", data);
  }

  generateBatchReport(data) {
    return http.post("/generateBatchReport/", data)
  }

  generateCompanyReport(data) {
    return http.post("/generateCompanyReport/", data)
  }

}

export default new PDFService();
