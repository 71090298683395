import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { BrowserRouter, BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthProvider, useAuth } from "../context/AuthContext";
import AdminRoutes from "./AdminRoutes";
import "./App.css";
import Dashboard from "./Dashboard";
import Login from "./Login";
import PrivateRoute from "./PrivateRoute";
import Sidebar from "./Sidebar";
import TaskOrder from "./TaskOrder.js";
import TaskOrderDetail from "./TaskOrderDetail";
import WeeklyForm from "./WeeklyForm";
import WeeklyReport from "./WeeklyReport"
import Logout from "./Logout"
import Report from "./Report"
import MyProfile from "./MyProfile";
import PasswordChange from "./PasswordChange"
import ForgotPassword from "./ForgotPassword"
import { ViewProvider } from "../context/ViewContext";
import CustomReport from "./CustomReport";
import EmailPasswordChange from "./EmailPasswordChange";
import MonthReport from "./MonthReport";
import LastMonthReport from "./LastMonthReport";
import QuarterReport from "./QuarterReport";
import DefaultPasswordChange from "./DefaultPasswordChange"
import EditResponse from "./EditResponse"

export const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  list: {
    width: 260,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  
}));

const drawerWidth = 240;

function App() {
  const currentUser = useAuth()
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  /*const handleDrawerOpen = () => {
    setOpen(true);
  };*/
  const handleDrawerClose = () => {
    setOpen(false);
  };
  //const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <>
    <BrowserRouter >
      <Router>
        
      <AuthProvider>
      <ViewProvider>
        <div className={classes.root} >
          <Sidebar
            open={open}
            handleDrawerClose={handleDrawerClose}
            useStyles={useStyles}
          />
          <Switch>
            <main className={classes.content}>
              
              <Route exact path="/login" component={Login} />
              <Route exact path="/logout" component={Logout} />
              <Route exact path="/user/emailPasswordReset" component={ForgotPassword} />
              <Route exact path="/defaultPasswordChange" component={DefaultPasswordChange} />
              <Route path = "/user/changePassword/" component={EmailPasswordChange} />
              <PrivateRoute path="/home" exact component={Dashboard} />
              <PrivateRoute exact path="/taskOrders/" component={TaskOrder} />
              <PrivateRoute exact path="/weeklyForm/" component={WeeklyForm} />
              <PrivateRoute
                path="/taskOrderDetail/:id"
                component={TaskOrderDetail}
              />
              <PrivateRoute exact path="/myProfile/" component={MyProfile} />
              <PrivateRoute exact path="/reports/" component={Report} />
              <PrivateRoute exact path="/changePassword/" component={PasswordChange} />
              <PrivateRoute exact path="/weeklyReport/" component={WeeklyReport} />
              <PrivateRoute exact path="/customReport/" component={CustomReport} />
              <PrivateRoute exact path="/monthReport/" component={MonthReport} />
              <PrivateRoute exact path="/lastMonthReport" component={LastMonthReport} />
              <PrivateRoute exact path="/lastQuarter/" component={QuarterReport} />
              <PrivateRoute path="/editResponse/" component={EditResponse} />
              <AdminRoutes />
              {currentUser ? <Route exact path="/" component={Dashboard}></Route> : <Route exact path="/" component={Login}></Route>}
            </main>
          </Switch>
        </div>
        </ViewProvider>
        </AuthProvider>
      </Router>
      </BrowserRouter>
      
      
    </>
  );
}

export default App;
