  import React, { useState, useEffect } from "react";
  import { useHistory } from "react-router-dom";
  import { useAuth } from "../context/AuthContext"
  import { Redirect, Route } from "react-router-dom"

  
  export default function Logout() {
    const { logout } = useAuth()
    const [loading, setLoading] = useState(true)
  

    useEffect(() => {
        logout();
        setLoading(false)
      }, []);
    
    
  
    return (
      <>
      {!loading ? <Redirect to='/login' /> : <></> }
        
      </>
    );
  }
  