import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormGroup,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useStyles } from "./App";
import { useAuth } from "../context/AuthContext";
import { setCurrentUser } from "../context/AuthContext";

import UserService from "../services/UserService";
import Swal from "sweetalert2";
import { useView } from "../context/ViewContext";

export default function MyProfile() {
  const emailRef = useRef();
  const [error, setError] = useState("");
  const currentView = useView();
  //const [loading, setLoading] = useState(false);
  const history = useHistory();

  const classes = useStyles();

  const sendEmail = () => {
    try {
      UserService.emailPasswordReset(emailRef.current.value).then(
        (response) => {
          if (response.data == "Success") {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "Please check your inbox",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Error!",
              text: "Something went wrong",
            });
          }
        }
      );
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: { e },
      });
    }
  };

  useEffect(() => {
    currentView.setCurrentView("Forgot Password");
  }, []);

  return (
    <>
      <main className={classes.content} style={{ padding: 10 }}>
        <div className={classes.appBarSpacer} />
        <Card>
          <CardContent>
            <h2 className="text-center mb-4">Change Password</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <FormControl>
              <FormGroup id="email">
                <TextField
                  id="email"
                  label="Enter Email"
                  inputRef={emailRef}
                  required
                />
              </FormGroup>
              <div style={{ paddingTop: 10 }}></div>
              <Button
                onClick={() => sendEmail()}
                variant="contained"
                color="primary"
                className="w-100"
                type="submit"
              >
                Email Password Reset
              </Button>
            </FormControl>
          </CardContent>
        </Card>
      </main>
    </>
  );
}
