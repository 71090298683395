import { FormControl } from "@material-ui/core/";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useRef } from "react";
import { useAuth } from "../../context/AuthContext";
import TaskOrderService from "../../services/TaskOrderService";
import { useStyles } from "../App";
import { useView } from "../../context/ViewContext";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

export default function CreateTaskOrder() {
  const number = useRef("");
  const description = useRef("");
  const currentView = useView();

  useEffect(() => {
    currentView.setCurrentView("Create Task Order");
  }, []);

  const handleSubmit = () => {
      let data = {
          number: number.current.value,
          description: description.current.value,
          pubDate: new Date(),
          active: true

      }
      TaskOrderService.createTaskOrder(data).then((response) => {
          number.current.value = ""
          description.current.value = ""

      })
  };

  const classes = useStyles();

  return (
    <>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Card>
            <CardContent>
              <h1>Create Task Order</h1>
            </CardContent>
          </Card>
<div style={{paddingTop: 10}}>
          <Card >
              
            <CardContent>
            <h3>Number</h3>
              <TextField id="number" inputRef={number} label="Task Order #" />
            </CardContent>
          </Card>
          </div>

<div style={{paddingTop: 10}}>

              
          <Card>
            <CardContent>
            <h3>Description</h3>
            <FormControl fullWidth="true">
              <TextField id="standard-textarea" inputRef={description} multiline label="Description" />
              </FormControl>
            </CardContent>
          </Card>
          
          </div>
          <div style={{paddingTop: 10}}>
              <Button variant="contained" color="primary" onClick={() => handleSubmit()}>Submit</Button>
              </div>
        </Container>
      </main>
    </>
  );
}
