import http from "../http-common";

class SubTaskOrderService {

  getAll() {
    return http.get()
  }

  getSubWithParent(id) {
    return http.get(`/subTaskOrders/${id}`)
  }

  createSubTaskOrder(data) {
    return http.post("/createSubTaskOrder/", data)
  }

  getSubTaskOrderDetails(id) {
    return http.get(`/getSubTaskOrderDetails/${id}`)
  }

  updateActive(data) {
    return http.post("/updateSubTaskOrderActive/", data)
  }

  saveDescription(data) {
    return http.post("/updateSubTaskOrderDescripton/", data)
  }

  updateSubTitle(data) {
    return http.post("/updateSubTitle/", data)
  }



}

export default new SubTaskOrderService();