import http from "../http-common";

class DashboardService {

  getUpdateTable() {
    return http.get("/getUpdateTable/")
  }

  getUpdateGraph(data) {
    return http.post('/getUpdateGraph/', data)
  }

}

export default new DashboardService();