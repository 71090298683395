import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";
import DashboardService from "../services/DashboardService";
import Title from "./Title";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function Orders() {
  const classes = useStyles();
  const [updates, setUpdates] = useState();

  const retrieveUpdates = () => {
    DashboardService.getUpdateTable().then((response) => {
      setUpdates(response.data);
    });
  };

  useEffect(() => {
    retrieveUpdates();
  }, []);

  return (
    <>
      <Title>Recent Updates</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>User</TableCell>
            <TableCell>Task Order</TableCell>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {updates ? (
            updates.map((update) => (
              <TableRow key={update.id}>
                <TableCell>{update.pubDate}</TableCell>
                <TableCell>{update.username}</TableCell>
                <TableCell>{update.taskOrder}</TableCell>
                <TableCell>{update.response}</TableCell>
              </TableRow>
            ))
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
      <div className={classes.seeMore}>
        {/*<Link color="primary" href="#" onClick={preventDefault}>
          See more updates
          </Link>*/}
      </div>
    </>
  );
}
