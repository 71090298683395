import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { endOfISOWeek, startOfISOWeek, subDays } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Redirect, useHistory } from "react-router-dom";
import { useView } from "../context/ViewContext";
import PDFService from "../services/PDFService";
import TaskOrderService from "../services/TaskOrderService";
import { useStyles } from "./App";
import useWindowSize from "./useWindowSize";

import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { useAuth } from "../context/AuthContext";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function CustomReport(props) {
  const currentUser = useAuth();
  const [taskOrderDetail, setTaskOrderDetail] = useState("");
  const [link, setLink] = useState();
  const [numPages, setNumPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [taskOrders, setTaskOrders] = useState([]);
  const [report, setReport] = useState();
  const currentView = useView();
  let newDate = subDays(new Date(), 7);
  const firstUpdate = useRef(true);
  const start = startOfISOWeek(newDate, { weekStartsOn: 1 }).getTime();
  const end = endOfISOWeek(newDate, { weekStartsOn: 1 }).getTime();
  const [selectedStartDate, setSelectedStartDate] = React.useState(start);
  const [selectedEndDate, setSelectedEndDate] = React.useState(end);
  const history = useHistory();
  const logout = false;

  const size = useWindowSize();

  const handleChange = (event) => {
    setTaskOrderDetail(event.target.value);
  };

  const advancePage = () => {
    if (currentPage == numPages) {
      setCurrentPage(1);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  const lastPage = () => {
    if (currentPage == 1) {
      setCurrentPage(numPages);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const retrieveTaskOrders = () => {
    TaskOrderService.getTaskOrdersForUser(currentUser.currentUser.id)
      .then((response) => {
        setTaskOrders(response.data);
        setTaskOrderDetail(response.data[0]);
      })
      .catch((error) => {
        history.push("/logout/");
      });
  };

  /*const retrieveTaskOrders = () => {
    TaskOrderService.getActiveTaskOrders().then((response) => {
      setTaskOrders(response.data);
    }).catch((error) => {
      logout = true;
      //history.push("/logout/")
    });
  };*/

  const generateCompanyReport = () => {
    var data = {
      id: currentUser.currentUser.id,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
    };

    PDFService.generateCompanyReport(data).then((response) => {
      setReport(response.data);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      setLink(link);
    });
  };

  const generateCustomReport = () => {
    if (taskOrderDetail == "batch") {
      generateBatchReport();
      return;
    }

    var data = {
      active: taskOrderDetail.active,
      description: taskOrderDetail.description,
      id: taskOrderDetail.id,
      number: taskOrderDetail.number,
      pubDate: taskOrderDetail.pubDate,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
    };

    PDFService.generateCustomReport(data).then((response) => {
      setReport(response.data);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      setLink(link);
    });
  };

  const generateBatchReport = () => {
    var data = {
      startDate: selectedStartDate,
      endDate: selectedEndDate,
    };

    PDFService.generateBatchReport(data).then((response) => {
      setReport(response.data);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      setLink(link);
    });
  };

  useEffect(() => {
    currentView.setCurrentView("Custom Reports");
  }, []);

  useEffect(() => {
    retrieveTaskOrders();
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const classes = useStyles();

  return (
    <>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {logout ? <Redirect to="/logout" /> : null}
        <Container maxWidth="xl" className={classes.container}>
          <Typography
            className={classes.title}
            variant="h2"
            color="textPrimary"
          >
            <b>Custom Reports</b>
          </Typography>
          <div style={{ paddingTop: 10 }}>
            <Button
              onClick={() =>
                currentUser.currentUser.roles[0].includes("COMPANY_LEAD")
                  ? generateCompanyReport()
                  : generateCustomReport()
              }
              variant="contained"
              color="primary"
            >
              Preview Custom Report
            </Button>
            {currentUser.currentUser.roles[0].includes("ADMIN") ||
            currentUser.currentUser.roles[0].includes("TO_LEAD") ? (
              <a
                href={link}
                download={
                  taskOrderDetail == `batch`
                    ? `TOBatchReport_${new Date(
                        endOfISOWeek(selectedStartDate, { weekStartsOn: 1 })
                      ).toLocaleDateString()}.pdf`
                    : currentUser.currentUser.roles[0].includes("COMPANY_LEAD")
                    ? `report_${new Date(
                        selectedEndDate
                      ).toLocaleDateString()}.pdf`
                    : `TO#${taskOrderDetail.number}_${new Date(
                        selectedEndDate
                      ).toLocaleDateString()}.pdf`
                }
                style={{ paddingLeft: 10 }}
              >
                Click to download
              </a>
            ) : null}
          </div>
          <MuiPickersUtilsProvider
            style={{ paddingBottom: 10 }}
            utils={DateFnsUtils}
          >
            <KeyboardDatePicker
              disableToolbar
              autoOk={true}
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Select Start Date"
              value={selectedStartDate}
              onChange={handleStartDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            &nbsp;
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider
            style={{ paddingBottom: 10 }}
            utils={DateFnsUtils}
          >
            <KeyboardDatePicker
              disableToolbar
              autoOk={true}
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Select End Date"
              value={selectedEndDate}
              onChange={handleEndDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            &nbsp;
          </MuiPickersUtilsProvider>
          {currentUser.currentUser.roles[0].includes("COMPANY_LEAD") ? null : (
            <FormControl size="large">
              <InputLabel style={{ padding: 8 }} id="taskOrder">
                Task Order
              </InputLabel>
              <Select
                id="taskOrderSelect"
                value={taskOrderDetail}
                onChange={handleChange}
                style={{ minWidth: 120, padding: 8 }}
              >
                {" "}
                {taskOrders.map((task) => (
                  <MenuItem value={task}>{task.number}</MenuItem>
                ))}
                {currentUser.currentUser.roles[0].includes("ADMIN") ? (
                  <MenuItem value={"batch"}>Batch</MenuItem>
                ) : null}
              </Select>
            </FormControl>
          )}
          <Card style={{ padding: 10, width: size.width / 2.5 }}>
            <CardContent>
              <div style={{ paddingBottom: 5 }}>
                <Typography
                  className={classes.title}
                  variant="h4"
                  color="textSecondary"
                >
                  Report Preview
                </Typography>
              </div>
              {report ? (
                <>
                  <div>
                    <Document
                      file={report}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      {
                        <div
                          style={{
                            width: size.width / 3.5,
                            height: size.width / 3.5 + size.height / 4,
                          }}
                        >
                          <Card raised={true}>
                            <Page
                              pageNumber={currentPage}
                              scale={size.width / 2000}
                            />
                          </Card>
                        </div>
                      }
                    </Document>

                    <div style={{ paddingTop: 40 }}>
                      Page {currentPage} of {numPages}
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => lastPage()}
                      >
                        <ArrowBackIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => advancePage()}
                      >
                        <ArrowForwardIcon fontSize="inherit" />
                      </IconButton>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </CardContent>
          </Card>
        </Container>
      </main>
    </>
  );
}
