import http from "../http-common";

class TaskOrderService {
  getAll() {
    return http.get("/taskOrders/");
  }

  getUsersForTask(id) {
    return http.get(`/getUsersForTask/${id}`);
  }

  getActiveTaskOrders() {
    return http.get("/activeTaskOrders/");
  }

  getActiveTaskOrdersForUser(id) {
    return http.get(`/activeTaskOrders/${id}`);
  }

  getTaskOrdersForUser(id) {
    return http.get(`/userTaskOrders/${id}`);
  }

  getResponsesForUser(id) {
    return http.get(`/responses/${id}`);
  }

  getUserUpdatesQuery(data) {
    return http.post("/getUserReportUpdate/", data);
  }

  updateTitle(data) {
    return http.post("/updateTitle/", data);
  }

  getTaskOrderDetail(id) {
    return http.get(`/taskOrders/${id}`);
  }

  getArchivedTaskOrders() {
    return http.get("/archivedTaskOrders/");
  }

  makeWeeklyReport(data) {
    return http.post("/weeklyReport/", data);
  }

  createTaskOrder(data) {
    return http.post("/createTaskOrder/", data);
  }

  getNumPages(data) {
    return http.post("/getNumPages/", data);
  }

  updateDescription(data) {
    return http.post("/updateDescription/", data);
  }

  updateActive(data) {
    return http.post("/updateActive/", data);
  }
}

export default new TaskOrderService();
