import http from "../http-common";

class UserService {
  getAllUsers() {
    return http.get("/getAllUsers/");
  }

  archiveUser(id) {
    return http.post(`/archiveUser/${id}`)
  }

  unarchiveUser(id) {
    return http.post(`/unarchiveUser/${id}`)
  }

  getUserInfo(id) {
    return http.get(`/getUserDetail/${id}`);
  }

  changePassword(data) {
    return http.post("/auth/changePassword", data);
  }

  createNewUser(data) {
    return http.post("/createNewUser/", data)
  }

  getCompanies() {
    return http.get("/getCompanies/")
  }

  updateUser(data) {
    return http.post("/updateUser/", data)
  }

  saveUserAssignments(id, data) {
    return http.post(`/saveUserAssignments/${id}`, data)
  }

  emailChangePassword(data) {
    return http.post("/auth/user/savePassword", data)
  }

  emailPasswordReset(email) {
    return http.post(`/auth/emailPasswordReset/${email}`)
  }

  resetPassword(id) {
    return http.post("/auth/resetPassword", id)
  }

  saveUserRoles(id, data) {
    return http.post(`/saveUserRoles/${id}`, data)
  }

  getUserRoles(id) {
    return http.post(`/getUserRoles/${id}`)
  }

}

export default new UserService();
