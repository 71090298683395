import React, { useContext, useEffect, useState } from "react";

const ViewContext = React.createContext();

export function useView() {
  return useContext(ViewContext);
}

export function ViewProvider({ children }) {
  const [currentView, setCurrentView] = useState("");

  useEffect(() => {
    setCurrentView("")
  }, [])

  const value = {
    currentView,
    setCurrentView
  };

  return (
    <ViewContext.Provider value={value}>
      {children}
    </ViewContext.Provider>
  );


}
