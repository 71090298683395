import React, { useContext, useEffect, useState } from "react";
import http from "../http-common";
import TokenService from "../services/TokenService";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  const login = (data) => {
    try {
      return http.post("/auth/signin", data).then((response) => {
        if (response.data.archived) {
          return response
        }
        if (response.data.accessToken) {
          TokenService.setUser(response.data);
          setCurrentUser(response.data);
          return response;
        }
      })
      .catch(error => {
        return error.message
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const signup = (data) => {
    return http.post("/auth/signup", data);
  };

  const logout = () => {
    TokenService.removeUser();
    setCurrentUser();
  };

  const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
  };

  const value = {
    login,
    signup,
    logout,
    getCurrentUser,
    currentUser,
    setCurrentUser,
    setLoading,
    loading,
  };

  useEffect(() => {
    setCurrentUser(JSON.parse(localStorage.getItem("user")));
    setLoading(false);
  }, []);

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
